<template>
  <div>
    <b-card no-body>
      <div>
          <!-- <b-form-group
                  style="max-width:250px">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                  id="icons-search"
                  v-model="filters['search']"
                  placeholder="Search"
              />
            </b-input-group>
          </b-form-group> -->
        </div>
        <b-row>
            <b-col cols="12">
            <b-overlay
            :show="tableLoading"
            rounded="sm"
            >
            <b-table
                ref="table"
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="getRollingLeaves"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                class="mobile_table_css"
                responsive
            >
              <template #cell(user)="data">
                <div style="width: 150px">
                  <b-row class="center text-primary">
                    <b-avatar
                        size="40"
                        :src="getUserImage(data.item.user_id)"
                    />
                    <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.user}}</p>
                    <br/>
                  </b-row>
                </div>
              </template>
              <template #cell(date)="data">
                <div style="width: 150px">
                  <b-row class="center text-primary">
                    <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.date}}</p>
                  </b-row>
                </div>
              </template>
              <template #cell(time)="data">
                <div style="width: 80px">
                  <b-row class="center text-primary">
                    <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.time}}</p>
                  </b-row>
                </div>
              </template>
            <template #cell(action)="data">
              <div style="width: 150px">
                <b-button
                    size="23"
                    variant="outline-white"
                    class="btn-icon shadow-sm bg-white text-primary mr-1"
                    v-b-tooltip.hover.top="'Edit'"
                    @click="toggleSideBars(data.item)"
                >
                  <feather-icon icon="EditIcon" size="18" />
                </b-button>

                <b-button
                    size="23"
                    variant="outline-white"
                    class="btn-icon shadow-sm bg-white text-danger"
                    v-b-tooltip.hover.top="'Cancel'"
                    @click="canceltLeave(data.item.id)"
                >

                  Cancel
                </b-button>

              </div>
                </template>

            </b-table>

            </b-overlay>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
            <!-- <span class="text-muted">Showing 10 out of 28 items</span> -->
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >

            <b-pagination
                v-model="currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                class="mb-0 mt-1 mt-sm-0 "
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item">

                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"  />
                </template>

            </b-pagination>

            </b-col>
        </b-row>
    </b-card>
    <EditLeaveSidebar
        ref="EditLeaveSidebar"
        :is-edit-leave-sidebar-active="isEditLeaveSidebarActive"
        :staff-details = "staffMember"
        @toggleSideBars="toggle()"
    />
  </div>
</template>

<script>
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import Filter from '@/mixins/FilterMixin'
import DateDiff from 'date-diff'
import EditLeaveSidebar from '@/views/admin-leave/sidebars/editLeaveSidebar'
import {
  BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow,
  BButton,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButtonGroup,
  BOverlay, VBTooltip
} from 'bootstrap-vue'

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay,
    BButton,
    EditLeaveSidebar,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      filters: {},
      filterOn: [],
      staffMember:{},
      isEditLeaveSidebarActive: false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableLoading: false,
      fields: [
        {
          key: 'user',
          label: 'NAME',
        },
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME',
        },
        {
          key: 'duration',
          label: 'DURATION',
        },
        {
          key: 'reason',
          label: 'Leave TYPE',
        },
        {
          key: 'approved_by',
          label: 'APPROVED BY',
        },
        {
          key: 'action',
          label: '',
        },


      ],
    }
    },
  methods:{
    async getRollingLeaves(){
      try{
        this.tableLoading = true
        const Response = await AdminLeaveAPI.getRollingLeaves( 5, this.currentPage, this.filterQuery)
        let dataArray =  Response.data.data.map((x) => ({
          id: x.id,
          date: x.is_day?`${this.momentFormat(x.start,'DD-MMM-YY')}`+` - ` +`${ this.momentFormat(x.end,'DD-MMM-YY')}`:this.momentFormat(x.start,'DD-MMM-YY'),
          start: x.start,
          is_day: x.is_day,
          end: x.end,
          duration: x.is_day?`${new DateDiff( new Date(x.end), new Date(x.start)).days()} days`: `${new DateDiff( new Date(x.end), new Date(x.start)).hours()} hours`,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
          sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
          user: x.user === null? 'N/A':x.user.first_name +' '+ x.user.last_name,
          user_id: x.user.id,
          approved_by: x.administrator === null ? 'N/A' : `${x.administrator.first_name}  ${x.administrator.last_name}`,
          status: x.state.charAt(0).toUpperCase() + x.state.substring(1),
          time:x.is_day?'Full day': `${this.momentFormat(x.start,'HH:mm')}` + ' - ' + `${this.momentFormat(x.end,'HH:mm')}`
        }))

        this.tableLoading = false
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        return dataArray
      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async canceltLeave(id)
    {
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure you want to cancel this leave?',
          text: '',
          imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, cancel',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {

          if (result.value) {
            await AdminLeaveAPI.cancelLeave(id)
            this.$refs.table.refresh()
            this.$emit('refreshData')
            this.tableLoading = false
          }
        })
        this.$refs.table.refresh()
        this.tableLoading = false

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    toggleSideBars(data){
      this.staffMember = data
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
    },
    async toggle() {
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
      this.$refs.table.refresh()
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
  },
  mounted(){
  }

}
</script>
