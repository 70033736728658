var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass:
            "todo-task-list-wrapper list-group scroll-area min-vh-50",
          staticStyle: { "max-height": "500px" }
        },
        _vm._l(_vm.locumInvoices, function(locumInvoice) {
          return _c("b-card", { staticClass: "space mr-1" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                { staticClass: "ml-1", staticStyle: { "padding-top": "2px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "#171822",
                        "font-family": "Mulish"
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(locumInvoice.statement_id))])]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-family": "Mulish",
                        "font-size": "12px",
                        color: "#949494"
                      }
                    },
                    [
                      _c("span", { staticClass: "mr-1" }, [
                        _vm._v(" " + _vm._s(locumInvoice.user_name) + " ")
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "float-end",
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "margin-left": "auto"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "float-right",
                      staticStyle: { display: "flex", "flex-direction": "row" }
                    },
                    [
                      _c(
                        "b-badge",
                        {
                          staticClass: "mr-1",
                          staticStyle: {
                            padding: "8px",
                            background: "#CCE6F4",
                            width: "72px",
                            height: "25px",
                            "border-radius": "4px"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#171822",
                                "font-size": "12px"
                              }
                            },
                            [_vm._v(" £" + _vm._s(locumInvoice.amount) + " ")]
                          )
                        ]
                      ),
                      _c("feather-icon", {
                        staticClass: "float-right",
                        staticStyle: { "padding-top": "5px", color: "#B2B2B2" },
                        attrs: { icon: "ChevronRightIcon", size: "25" },
                        on: {
                          click: function($event) {
                            return _vm.gotoLocumInvoices()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        }),
        1
      ),
      _c(
        "div",
        [
          _vm.noDataTable === 0
            ? _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "empty-state py-5" }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      staticStyle: { width: "150px", height: "150px" },
                      attrs: {
                        src: require("@/assets/images/pages/doc-icon.png"),
                        alt: ""
                      }
                    }),
                    _c("p", { staticClass: "txt-grey mt-1" }, [
                      _vm._v("No Locum Invoices")
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }