<template>
  <div>
    <vue-perfect-scrollbar
        class="todo-task-list-wrapper list-group scroll-area min-vh-50"
        style="max-height: 500px"
    >
      <b-card v-for="locumInvoice in locumInvoices" class="space mr-1">
        <div class="d-flex">
          <div class="ml-1" style="padding-top:2px">
            <div style="font-size: 14px; color: #171822; font-family: Mulish">
              <span>{{ locumInvoice.statement_id }}</span>
            </div>
            <div style="font-family: Mulish; font-size: 12px; color: #949494;">
            <span class="mr-1">
                  {{ locumInvoice.user_name}}
                </span>
            </div>
          </div>
          <div class="float-end" style="display: flex; flex-direction: row;  margin-left: auto;">
            <div class="float-right" style="display: flex; flex-direction: row;">
      <b-badge  class="mr-1" style="padding:8px; background: #CCE6F4; width: 72px; height: 25px; border-radius: 4px;">
        <span class="" style="color: #171822; font-size: 12px;"> £{{locumInvoice.amount}} </span>
      </b-badge>

      <feather-icon
          icon="ChevronRightIcon"
          size="25"
          class="float-right"
          style="padding-top: 5px; color: #B2B2B2;"
          @click="gotoLocumInvoices()"
      />
            </div>
          </div>
        </div>

      </b-card>
    </vue-perfect-scrollbar>

<!--    <b-row v-if="noDataTable !== 0">-->
<!--      <b-col cols="12">-->

<!--        <b-table-->
<!--            :current-page="currentPage"-->
<!--            :per-page="pagination.perPage"-->
<!--            :fields="fields"-->
<!--            :items="getInvoices"-->
<!--            :sort-by.sync="sortBy"-->
<!--            :sort-desc.sync="sortDesc"-->
<!--            hover-->
<!--            ref="table"-->

<!--            class="mobile_table_css table_shifts"-->

<!--            responsive-->
<!--        >-->
<!--          <template #cell(name)="data">-->
<!--            <div class="d-flex ">-->
<!--              <b-avatar-->
<!--                  size="40"-->
<!--                  :src="getUserImage(data.item.user_id)"-->
<!--              />-->


<!--              <div class="ml-1" style="padding-top:8px">-->
<!--                <h5 class=" font-weight-700">-->
<!--                  <span>{{data.item.statement_id}}</span>-->
<!--                </h5>-->
<!--                <span class="mr-1">-->
<!--                {{ data.item.user_name }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->

<!--          <template #cell(action)="data">-->
<!--            <div class="float-right" style="display: flex; flex-direction: row;">-->
<!--              <b-badge  class="mr-1" style="padding:8px; background-color: #CCE6F4;">-->
<!--                <span class=""> {{ data.item.amount}} </span>-->
<!--              </b-badge>-->

<!--              <feather-icon-->
<!--                  icon="ChevronRightIcon"-->
<!--                  variant="success"-->
<!--                  size="18"-->
<!--                  class="float-right"-->
<!--                  @click="gotoLocumInvoices()"-->
<!--              />-->
<!--            </div>-->
<!--          </template>-->
<!--        </b-table>-->
<!--      </b-col>-->

<!--      <b-col cols="12" class="position-absolute pagination_style">-->
<!--        <b-row>-->
<!--      <b-col class="d-flex align-items-center pl-1 pb-1 justify-content-center justify-content-sm-start" cols="12"-->
<!--             sm="6">-->
<!--        <span class="text-muted pl-1">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>-->
<!--      </b-col>-->

<!--      <b-col class="d-flex align-items-center pr-0 pb-1 justify-content-center justify-content-sm-end" cols="12" sm="6">-->
<!--        <b-pagination-->
<!--            v-model="currentPage"-->
<!--            :per-page="pagination.perPage"-->
<!--            :total-rows="pagination.totalRows"-->
<!--            class="mb-0 mt-1 mt-sm-0 "-->
<!--            first-number-->
<!--            last-number-->
<!--            next-class="next-item"-->
<!--            prev-class="prev-item">-->

<!--          <template #prev-text>-->
<!--            <feather-icon icon="ChevronLeftIcon" size="18"/>-->
<!--          </template>-->

<!--          <template #next-text>-->
<!--            <feather-icon icon="ChevronRightIcon" size="18"/>-->
<!--          </template>-->

<!--        </b-pagination>-->
<!--      </b-col>-->
<!--          </b-row>-->
<!--      </b-col>-->

<!--    </b-row>-->
    <div>
      <b-col cols="12" v-if="noDataTable === 0" class="text-center">
<!--        <span class="font-weight-bolder" style="font-size: larger; color: #949494;">No data for preview</span>-->
        <div class="empty-state py-5">
          <img class="img-fluid" style="width:150px; height:150px;" src="@/assets/images/pages/doc-icon.png" alt="">
          <p class="txt-grey mt-1">No Locum Invoices</p>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BPagination,
  BOverlay,
  BTable, VBToggle, VBTooltip
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import dashboard from '@/apis/modules/dashboard'
import Ripple from 'vue-ripple-directive'
import invoice from '@/apis/modules/invoice'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BPagination,
    BOverlay,
    BTable,
    VBTooltip,
    VBToggle,
    VuePerfectScrollbar
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  mixins: [MomentMixin],
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: ''
        },

        {
          key: 'action',
          label: ''
        }
      ],
      items: [],
      locumInvoices: [],
      tableLoading: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false
    }
  },
  methods: {
    async getInvoices () {
      try {
        const Response = await dashboard.getInvoicesList(this.sortBy, this.sortDesc)
        this.locumInvoices = Response.data.data.map((x) => ({
          id: x.id,
          user_name: x.user.first_name + ' ' + x.user.last_name,
          statement_id: x.id,
          issue_date: x.issue_date,
          due_date: x.due_date,
          amount: x.total_invoice_cost,
          status: x.state

        }))
        this.noDataTable = Response.data.data.length
        // const paginationResponse = Response.data.meta
        // this.currentPage = paginationResponse.current_page
        // this.pagination.totalRows = paginationResponse.total
        // this.pagination.perPage = paginationResponse.per_page
        // this.pagination.from = paginationResponse.from
        // this.pagination.to = paginationResponse.to
        // this.tableLoading = false
        return this.locumInvoices

      } catch (e) {
        this.convertAndNotifyError(e)
        this.tableLoading = false

      }
    },

    gotoLocumInvoices() {
      this.$router.push(`/payments`)
    }

  },

  mounted () {
    this.getInvoices()
  }
}
</script>

<style>
.table_shifts table thead{
  display: none !important;
}

.pagination_style{
  margin-top: 450px;
}

.space {
  margin-bottom: 8px;
}

</style>