<template>
  <div>
    <vue-perfect-scrollbar
        class="todo-task-list-wrapper list-group scroll-area min-vh-50"
        style="max-height: 500px"
    >
      <b-card :key="swapApplication" v-for="swapApplication in swapApplications" class="space mr-1">
        <div class="d-flex ">
          <div class="ml-1" style="padding-top:2px">
            <div style="font-size: 14px; color: #171822; font-family: Mulish">
              <span>{{ swapApplication.shift_user }}( {{swapApplication.shift_start_date}}) wants to swap shifts with  {{ swapApplication.swap_shift_user }}({{swapApplication.swap_shift_start_date}})
              </span>
            </div>
            <div style="font-family: Mulish; font-size: 12px; color: #949494;">
            <span class="mr-1">

                </span>
            </div>
          </div>
          <div class="float-end" style="display: flex; flex-direction: row;  margin-left: auto;">
            <b-button
                size="23"
                variant="outline-white"
                v-b-tooltip.hover.top="'Accept'"
                class="btn-icon shadow-sm bg-white text-primary p-1"
                @click="acceptUserApplication(swapApplication.id)"
            >
              <feather-icon icon="CheckSquareIcon" size="18" />
            </b-button>

            <b-button
                size="23"
                variant="outline-white"
                v-b-tooltip.hover.top="'Reject'"
                class="btn-icon ml-1 shadow-sm bg-white text-danger p-1"
                @click="rejectUserApplication(swapApplication.id)"
            >
              <feather-icon icon="XSquareIcon" size="18" />
            </b-button>
          </div>
        </div>

      </b-card>
    </vue-perfect-scrollbar>
    <div>
      <b-col cols="12" v-if="noDataTable === 0" class="text-center">
        <div class="empty-state py-5">
          <img class="img-fluid" src="@/assets/images/pages/shift-icon.png" alt="">
          <p class="txt-grey">No Swap Shifts</p>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow, BPagination,
  BOverlay,
  BTable, VBToggle, VBTooltip
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import dashboard from '@/apis/modules/dashboard'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import shift from "@/apis/modules/shift";

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    BPagination,
    BOverlay,
    BTable,
    VBTooltip,
    VBToggle,
    VuePerfectScrollbar
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  mixins: [MomentMixin],
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: ''
        },

        {
          key: 'action',
          label: ''
        }
      ],
      items: [],
      leaves: [],
      tableLoading: false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      swapApplications:[]
    }
  },
  methods: {
    async getShiftApplications () {
      const response = await dashboard.getSwapApplications();
      this.swapApplications = response.data.data.map((x) => ({
        id: x.id,
        shift:x.shift,
        shift_start_date:this.momentFormat(x.shift.start,'DD-MM-YYYY'),
        swap_shift_start_date:this.momentFormat(x.swap_shift.start,'DD-MM-YYYY'),
        swapShift:x.swap_shift,
        shift_user:x.shift.assignee.first_name +' '+x.shift.assignee.last_name,
        swap_shift_user:x.swap_shift.assignee.first_name +' '+ x.swap_shift.assignee.last_name,
      }))
      this.noDataTable = response.data.data.length
      return  this.swapApplications
    },

     async acceptUserApplication (id) {
      this.$swal({
        title: 'Are you sure you want to accept this swap request?',
        imageUrl: require('@/assets/images/icons/publish.png'),
        imageWidth: 80,
        imageHeight: 80,
        text: '',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
          if(result.value){
            try{
             // await shift.swapRequestAccept(id);
              await dashboard.acceptApplicationISwap(id)
              this.showSuccessMessage('Invitation accepted successfully')
              await this.getShiftApplications()
            }catch(error){
              this.showSuccessMessage('Invitation accepted successfully')
              await this.getShiftApplications()
            
            }
          }
        })
      this.tableLoading = false
    },

    async rejectUserApplication(id) {
      this.$swal({
        title: 'Are you sure you want to reject this swap request?',
        text: '',
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, reject',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
           if (result.value) {
              try{
              //  await shift.swapRequestReject(id);
                await dashboard.rejectApplicationSwap(id)
                this.showSuccessMessage('Invitation rejected successfully')
                await this.getShiftApplications()
              }catch(error){
                this.showSuccessMessage('Invitation rejected successfully')
                await this.getShiftApplications()
            
              }
           }
        })
      this.tableLoading = false
    },


  },

  mounted () {
    this.getShiftApplications()
  }
}
</script>

<style>
.table_shifts table thead{
  display: none !important;
}

.pagination_style{
  margin-top: 450px;
}

.space {
  margin-bottom: 8px;
}

</style>
