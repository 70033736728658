var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            { staticClass: " pl-1 " },
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("span", {}, [
                                _c("strong", [_vm._v("Pending requests")]),
                                _vm.pendingRequestCount > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "ml-1 translate-middle badge rounded-circle bg-danger",
                                        staticStyle: { "font-size": "0.8em" }
                                      },
                                      [_vm._v(_vm._s(_vm.pendingRequestCount))]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-overlay",
                                        {
                                          attrs: {
                                            show: _vm.tableLoading,
                                            rounded: "sm"
                                          }
                                        },
                                        [
                                          _c("b-table", {
                                            ref: "table",
                                            staticClass: "mobile_table_css",
                                            attrs: {
                                              "current-page": _vm.currentPage,
                                              "per-page":
                                                _vm.pagination.perPage,
                                              fields: _vm.fields,
                                              items: _vm.getScheduledLeaves,
                                              "sort-by": _vm.sortBy,
                                              "sort-desc": _vm.sortDesc,
                                              "sort-direction":
                                                _vm.sortDirection,
                                              hover: "",
                                              responsive: ""
                                            },
                                            on: {
                                              "update:sortBy": function(
                                                $event
                                              ) {
                                                _vm.sortBy = $event
                                              },
                                              "update:sort-by": function(
                                                $event
                                              ) {
                                                _vm.sortBy = $event
                                              },
                                              "update:sortDesc": function(
                                                $event
                                              ) {
                                                _vm.sortDesc = $event
                                              },
                                              "update:sort-desc": function(
                                                $event
                                              ) {
                                                _vm.sortDesc = $event
                                              }
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "cell(user)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "150px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass:
                                                              "center text-primary"
                                                          },
                                                          [
                                                            _c("b-avatar", {
                                                              attrs: {
                                                                size: "40",
                                                                src: _vm.getUserImage(
                                                                  data.item
                                                                    .user_id
                                                                )
                                                              }
                                                            }),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "ml-1 font-weight-bolder pt-1 text-dark"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .user
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c("br")
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(date)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "150px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass:
                                                              "center text-primary"
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "ml-1 font-weight-bolder pt-1 text-dark"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .date
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(time)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "80px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          {
                                                            staticClass:
                                                              "center text-primary"
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "ml-1 font-weight-bolder pt-1 text-dark"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .time
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "cell(action)",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "180px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover.top",
                                                                value: "Edit",
                                                                expression:
                                                                  "'Edit'",
                                                                modifiers: {
                                                                  hover: true,
                                                                  top: true
                                                                }
                                                              }
                                                            ],
                                                            staticClass:
                                                              "btn-icon shadow-sm bg-white text-primary mr-1",
                                                            attrs: {
                                                              size: "23",
                                                              variant:
                                                                "outline-white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.toggleSideBars(
                                                                  data.item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon:
                                                                  "EditIcon",
                                                                size: "18"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover.top",
                                                                value: "Accept",
                                                                expression:
                                                                  "'Accept'",
                                                                modifiers: {
                                                                  hover: true,
                                                                  top: true
                                                                }
                                                              }
                                                            ],
                                                            staticClass:
                                                              "btn-icon shadow-sm bg-white text-primary mr-1",
                                                            attrs: {
                                                              size: "23",
                                                              variant:
                                                                "outline-white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.acceptLeave(
                                                                  data.item.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon:
                                                                  "CheckSquareIcon",
                                                                size: "18"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "b-tooltip",
                                                                rawName:
                                                                  "v-b-tooltip.hover.top",
                                                                value: "Reject",
                                                                expression:
                                                                  "'Reject'",
                                                                modifiers: {
                                                                  hover: true,
                                                                  top: true
                                                                }
                                                              }
                                                            ],
                                                            staticClass:
                                                              "btn-icon shadow-sm bg-white text-danger",
                                                            attrs: {
                                                              size: "23",
                                                              variant:
                                                                "outline-white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.rejectLeave(
                                                                  data.item.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon:
                                                                  "XSquareIcon",
                                                                size: "18",
                                                                variant:
                                                                  "outline-danger"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _vm.pagination.totalRows !== 0
                                        ? _c(
                                            "span",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                "Showing " +
                                                  _vm._s(_vm.pagination.from) +
                                                  " to " +
                                                  _vm._s(_vm.pagination.to) +
                                                  " of " +
                                                  _vm._s(
                                                    _vm.pagination.totalRows
                                                  ) +
                                                  " entries"
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("b-pagination", {
                                        staticClass: "mb-0 mt-1 mt-sm-0 ",
                                        attrs: {
                                          "per-page": _vm.pagination.perPage,
                                          "total-rows":
                                            _vm.pagination.totalRows,
                                          "first-number": "",
                                          "last-number": "",
                                          "next-class": "next-item",
                                          "prev-class": "prev-item"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "prev-text",
                                            fn: function() {
                                              return [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    icon: "ChevronLeftIcon",
                                                    size: "18"
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "next-text",
                                            fn: function() {
                                              return [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    icon: "ChevronRightIcon",
                                                    size: "18"
                                                  }
                                                })
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.currentPage,
                                          callback: function($$v) {
                                            _vm.currentPage = $$v
                                          },
                                          expression: "currentPage"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("span", {}, [
                                _c("strong", [
                                  _vm._v(
                                    "Today's leaves (" +
                                      _vm._s(_vm.todaysLeaveCount) +
                                      ")"
                                  )
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert-body" },
                        [_c("TodaysLeaves")],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: { lazy: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("span", {}, [
                                _c("strong", [
                                  _vm._v(
                                    "On leave in the next 4 weeks (" +
                                      _vm._s(_vm.leaveRolling) +
                                      ")"
                                  )
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "alert-body" },
                        [_c("WeeklyRolling")],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("EditLeaveSidebar", {
        ref: "EditLeaveSidebar",
        attrs: {
          "is-edit-leave-sidebar-active": _vm.isEditLeaveSidebarActive,
          "staff-details": _vm.staffMember
        },
        on: {
          toggleSideBars: function($event) {
            return _vm.toggle()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }