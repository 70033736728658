import { render, staticRenderFns } from "./swapShifts.vue?vue&type=template&id=1948e19a&"
import script from "./swapShifts.vue?vue&type=script&lang=js&"
export * from "./swapShifts.vue?vue&type=script&lang=js&"
import style0 from "./swapShifts.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\10QBIT\\Hyre\\hyre_mp_fe\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1948e19a')) {
      api.createRecord('1948e19a', component.options)
    } else {
      api.reload('1948e19a', component.options)
    }
    module.hot.accept("./swapShifts.vue?vue&type=template&id=1948e19a&", function () {
      api.rerender('1948e19a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/views/swapShifts.vue"
export default component.exports