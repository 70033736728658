<template>
  <b-sidebar
      id="manage-leave-sidebar-sidebar"
      :visible="isDashboardSettingsSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-dashboard-settings-sidebar-active', val)"

  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
        Dashboard settings
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <div class="ml-2 mt-2" style="font-size: 14px; color: #171822;">
      <span>You can customise your dashboard by hiding sections and changing their order.</span>
      </div>



      <div class="box-item ml-2 mr-2 d-flex flex-row">
        <div class="" style="padding-top:4px; padding-left: 5px;">
        <b-avatar variant="">
        <feather-icon icon="AlignJustifyIcon" size="18" style="color:#B2B2B2;"/>
       </b-avatar>
          </div>
        <div style="padding-top:0px">
          <span class="pl-1" style="font-size:20px; color: #E5E5E5;">|</span>
        </div>
          <div class="ml-1" style="padding-top:4px; font-size: 14px; color: #171822; font-weight: bold;">Shift applications</div>
        <b-row class="ml-auto" style="padding-top:4px">
          <div class="ml-5">
            <!-- <feather-icon
                icon="SettingsIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="" style="margin-left: 5px;">
            <!-- <feather-icon
                icon="ChevronDownIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="ml-2 mr-1">
            <b-form-checkbox
                v-model="shiftApplication"
                name="check-button"
                switch
            >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" style="color: white"/>
                        </span>
              <span class="switch-icon-right">
                          <feather-icon icon="XIcon" style="color: rgba(8, 4, 51, 0.5);" />
                        </span>
            </b-form-checkbox>

          </div>
        </b-row>
      </div>


      <div class="box-item ml-2 mr-2 d-flex flex-row">
        <div class="" style="padding-top:4px; padding-left: 5px;">
          <b-avatar variant="">
            <feather-icon icon="AlignJustifyIcon" size="18" style="color:#B2B2B2;"/>
          </b-avatar>
        </div>
        <div style="padding-top:0px">
          <span class="pl-1" style="font-size:20px; color: #E5E5E5;">|</span>
        </div>
        <div class="ml-1" style="padding-top:4px; font-size: 14px; color: #171822; font-weight: bold;">Connection requests</div>
        <b-row class="ml-auto" style="padding-top:4px">
          <div class="ml-5">
            <!-- <feather-icon
                icon="SettingsIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="" style="margin-left: 5px;">
            <!-- <feather-icon
                icon="ChevronDownIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="ml-2 mr-1">
            <b-form-checkbox
                name="check-button"
                v-model="connectionRequest"
                switch
            >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" style="color: white"/>
                        </span>
              <span class="switch-icon-right">
                          <feather-icon icon="XIcon" style="color: rgba(8, 4, 51, 0.5);" />
                        </span>
            </b-form-checkbox>

          </div>
        </b-row>
      </div>

      <div class="box-item ml-2 mr-2 d-flex flex-row">
        <div class="" style="padding-top:4px;  padding-left: 5px;">
          <b-avatar variant="">
            <feather-icon icon="AlignJustifyIcon" size="18" style="color:#B2B2B2;"/>
          </b-avatar>
        </div>
        <div style="padding-top:0px">
          <span class="pl-1" style="font-size:20px; color: #E5E5E5;">|</span>
        </div>
        <div class="ml-1" style="padding-top:4px; font-size: 14px; color: #171822; font-weight: bold;">Timesheets</div>
        <b-row class="ml-auto" style="padding-top:4px">
          <div class="ml-5" v-if="timeSheet === false">
            <!-- <feather-icon
                icon="SettingsIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="" style="margin-left: 5px;" v-if="timeSheet === false">
            <!-- <feather-icon
                icon="ChevronDownIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>



          <div class="ml-2 mr-1">
            <b-form-checkbox
                name="check-button"
                v-model="timeSheet"
                switch
            >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" style="color: white"/>
                        </span>
              <span class="switch-icon-right">
                          <feather-icon icon="XIcon" style="color: rgba(8, 4, 51, 0.5);" />
                        </span>
            </b-form-checkbox>

          </div>
        </b-row>
      </div>

      <div class="box-item ml-2 mr-2 d-flex flex-row">
        <div class="" style="padding-top:4px; padding-left: 5px;">
          <b-avatar variant="">
            <feather-icon icon="AlignJustifyIcon" size="18" style="color:#B2B2B2;"/>
          </b-avatar>
        </div>
        <div style="padding-top:0px">
          <span class="pl-1" style="font-size:20px; color: #E5E5E5;">|</span>
        </div>
        <div class="ml-1" style="padding-top:4px; font-size: 14px; color: #171822; font-weight: bold;">Locum Requests</div>
        <b-row class="ml-auto" style="padding-top:4px">
          <div class="ml-5">
            <!-- <feather-icon
                icon="SettingsIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="" style="margin-left: 5px;">
            <!-- <feather-icon
                icon="ChevronDownIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="ml-2 mr-1">
            <b-form-checkbox
                v-model="locumRequest"
                name="check-button"
                switch
            >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" style="color: white"/>
                        </span>
              <span class="switch-icon-right">
                          <feather-icon icon="XIcon" style="color: rgba(8, 4, 51, 0.5);" />
                        </span>
            </b-form-checkbox>

          </div>
        </b-row>
      </div>


      <div class="box-item ml-2 mr-2 d-flex flex-row">
        <div class="" style="padding-top:4px; padding-left: 5px;">
          <b-avatar variant="">
            <feather-icon icon="AlignJustifyIcon" size="18" style="color:#B2B2B2;"/>
          </b-avatar>
        </div>
        <div style="padding-top:0px">
          <span class="pl-1" style="font-size:20px; color: #E5E5E5;">|</span>
        </div>
        <div class="ml-1" style="padding-top:4px; font-size: 14px; color: #171822; font-weight: bold;">Leaves</div>
        <b-row class="ml-auto" style="padding-top:4px">
          <div class="ml-5" v-if="leaves_rolling === false">
          </div>
          <div class="" style="margin-left: 5px;" v-if="leaves_rolling === false">
          </div>
          <div class="ml-2 mr-1">
            <b-form-checkbox
              v-model="leaves_rolling"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" style="color: white"/>
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" style="color: rgba(8, 4, 51, 0.5);" />
              </span>
              </b-form-checkbox>
          </div>
        </b-row>
      </div>

      <div class="box-item ml-2 mr-2 d-flex flex-row">
        <div class="" style="padding-top:4px; padding-left: 5px;">
          <b-avatar variant="">
            <feather-icon icon="AlignJustifyIcon" size="18" style="color:#B2B2B2;"/>
          </b-avatar>
        </div>
        <div style="padding-top:0px">
          <span class="pl-1" style="font-size:20px; color: #E5E5E5;">|</span>
        </div>
        <div class="ml-1" style="padding-top:4px; font-size: 14px; color: #171822; font-weight: bold;">Shift swap Requests</div>
        <b-row class="ml-auto" style="padding-top:4px">
          <div class="ml-5">
          </div>
          <div class="" style="margin-left: 5px;">
            <!-- <feather-icon
                icon="ChevronDownIcon"
                style="color: #E5E5E5;"
                size="18"
                class="mr-0  btn-icon  "

            /> -->
          </div>
          <div class="ml-2 mr-1">
            <b-form-checkbox
                v-model="swap_shift"
                name="check-button"
                switch
            >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" style="color: white"/>
                        </span>
              <span class="switch-icon-right">
                          <feather-icon icon="XIcon" style="color: rgba(8, 4, 51, 0.5);" />
                        </span>
            </b-form-checkbox>

          </div>
        </b-row>
      </div>

      <div class="d-flex  mt-5 ml-2">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click ="updateDashboard"
        >
          Save
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="text-primary ml-1 shadow"
            type="button"
            variant="outline-white"
            @click="hide"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAvatar,
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormTextarea,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BBadge,
  BOverlay,
  BTable,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import ErrorMessages from '@/mixins/ErrorMessages'
import MomentMixin from '@/mixins/MomentMixin'
import dashboard from '@/apis/modules/dashboard'

export default {
  mixins: [MomentMixin, ErrorMessages],

  components: {
    BAvatar,
    flatPickr,
    vSelect,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup

  },
  directives: {
    Ripple
  },

  model: {
    prop: 'isDashboardSettingsSidebarActive',
    event: 'update:is-dashboard-settings-sidebar-active'
  },
  props: {
    isDashboardSettingsSidebarActive: {
      type: Boolean
    },

    shiftApplications: {
    },

    connectionRequests: {
    },

    timeSheets: {
    },

    leaveRequests: {
    },

    locumRequests: {
    },

    locumInvoices: {
    }
  },
  data () {
    return {
        shiftApplication : false,
        connectionRequest: false,
        timeSheet: false,
        leaveRequest: false,
        locumInvoice: false,
        locumRequest: false,
        leaves_rolling: false,
        swap_shift:false
    }
  },
  watch:{
    // shiftApplications() {
    //   this.getDetailsOfDashboard()
    // },
    //
    // connectionRequests() {
    //   this.getDetailsOfDashboard()
    // },
    //
    // timeSheets() {
    //   this.getDetailsOfDashboard()
    // },
    //
    // leaveRequests() {
    //   this.getDetailsOfDashboard()
    // },
    //
    // locumRequests() {
    //   this.getDetailsOfDashboard()
    // },
    //
    // locumInvoices() {
    //   this.getDetailsOfDashboard()
    // }
    isDashboardSettingsSidebarActive(){
      this.getDetailsOfDashboard()
    }

  },
  methods: {
    toggle () {
      this.isDashboardSettingsSidebarActive = false
    },

    async updateDashboard() {
      try {
        let payload = {
          leave_requests: this.leaveRequest,
          shift_applications: this.shiftApplication,
          locum_requests: this.locumRequest,
          connection_requests: this.connectionRequest,
          time_sheets: this.timeSheet,
          locum_invoices: this.locumInvoice,
          leaves: this.leaves_rolling,
          swap_shift : this.swap_shift
        }
        const response = await dashboard.updateDashboard(payload)
        await this.getDetailsOfDashboard()
        this.toggle()
      }catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async getDetailsOfDashboard() {
      try {
        const response = await dashboard.getUpdateDashboardDetails()
        this.shiftApplication = response.data[0].shift_applications === 1 ? true : false
        this.connectionRequest = response.data[0].connection_requests === 1 ? true : false
        this.timeSheet = response.data[0].time_sheets === 1 ? true : false
        this.leaveRequest = response.data[0].leave_requests === 1 ? true : false
        this.locumInvoice = response.data[0].locum_invoices === 1 ? true : false
        this.locumRequest = response.data[0].locum_requests === 1 ? true : false
        this.leaves_rolling = response.data[0].leaves === 1 ? true : false
        this.swap_shift = response.data[0].swap_shift === 1 ? true : false
        this.$emit('updateOfDashboard', response.data[0])
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
  },

  mounted () {
    this.getDetailsOfDashboard()
  },


}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-select.scss';


.box-item{
  //width: 90%;
  //border: 1px solid grey;
  //padding: 10px;
  //margin: 20px;
  width: 90%;
  height: 48px;
  left: 916px;
  top: 146px;

  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
}

</style>
<style lang="scss" scoped>


</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
