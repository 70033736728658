var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "manage-leave-sidebar-sidebar",
      visible: _vm.isDashboardSettingsSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-dashboard-settings-sidebar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }, [
                  _vm._v(" Dashboard settings ")
                ]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "20" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "ml-2 mt-2",
                staticStyle: { "font-size": "14px", color: "#171822" }
              },
              [
                _c("span", [
                  _vm._v(
                    "You can customise your dashboard by hiding sections and changing their order."
                  )
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "box-item ml-2 mr-2 d-flex flex-row" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "padding-top": "4px", "padding-left": "5px" }
                  },
                  [
                    _c(
                      "b-avatar",
                      { attrs: { variant: "" } },
                      [
                        _c("feather-icon", {
                          staticStyle: { color: "#B2B2B2" },
                          attrs: { icon: "AlignJustifyIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticStyle: { "padding-top": "0px" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pl-1",
                      staticStyle: { "font-size": "20px", color: "#E5E5E5" }
                    },
                    [_vm._v("|")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: {
                      "padding-top": "4px",
                      "font-size": "14px",
                      color: "#171822",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v("Shift applications")]
                ),
                _c(
                  "b-row",
                  {
                    staticClass: "ml-auto",
                    staticStyle: { "padding-top": "4px" }
                  },
                  [
                    _c("div", { staticClass: "ml-5" }),
                    _c("div", { staticStyle: { "margin-left": "5px" } }),
                    _c(
                      "div",
                      { staticClass: "ml-2 mr-1" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { name: "check-button", switch: "" },
                            model: {
                              value: _vm.shiftApplication,
                              callback: function($$v) {
                                _vm.shiftApplication = $$v
                              },
                              expression: "shiftApplication"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "switch-icon-left" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { icon: "CheckIcon" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "switch-icon-right" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "rgba(8, 4, 51, 0.5)" },
                                  attrs: { icon: "XIcon" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box-item ml-2 mr-2 d-flex flex-row" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "padding-top": "4px", "padding-left": "5px" }
                  },
                  [
                    _c(
                      "b-avatar",
                      { attrs: { variant: "" } },
                      [
                        _c("feather-icon", {
                          staticStyle: { color: "#B2B2B2" },
                          attrs: { icon: "AlignJustifyIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticStyle: { "padding-top": "0px" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pl-1",
                      staticStyle: { "font-size": "20px", color: "#E5E5E5" }
                    },
                    [_vm._v("|")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: {
                      "padding-top": "4px",
                      "font-size": "14px",
                      color: "#171822",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v("Connection requests")]
                ),
                _c(
                  "b-row",
                  {
                    staticClass: "ml-auto",
                    staticStyle: { "padding-top": "4px" }
                  },
                  [
                    _c("div", { staticClass: "ml-5" }),
                    _c("div", { staticStyle: { "margin-left": "5px" } }),
                    _c(
                      "div",
                      { staticClass: "ml-2 mr-1" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { name: "check-button", switch: "" },
                            model: {
                              value: _vm.connectionRequest,
                              callback: function($$v) {
                                _vm.connectionRequest = $$v
                              },
                              expression: "connectionRequest"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "switch-icon-left" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { icon: "CheckIcon" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "switch-icon-right" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "rgba(8, 4, 51, 0.5)" },
                                  attrs: { icon: "XIcon" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box-item ml-2 mr-2 d-flex flex-row" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "padding-top": "4px", "padding-left": "5px" }
                  },
                  [
                    _c(
                      "b-avatar",
                      { attrs: { variant: "" } },
                      [
                        _c("feather-icon", {
                          staticStyle: { color: "#B2B2B2" },
                          attrs: { icon: "AlignJustifyIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticStyle: { "padding-top": "0px" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pl-1",
                      staticStyle: { "font-size": "20px", color: "#E5E5E5" }
                    },
                    [_vm._v("|")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: {
                      "padding-top": "4px",
                      "font-size": "14px",
                      color: "#171822",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v("Timesheets")]
                ),
                _c(
                  "b-row",
                  {
                    staticClass: "ml-auto",
                    staticStyle: { "padding-top": "4px" }
                  },
                  [
                    _vm.timeSheet === false
                      ? _c("div", { staticClass: "ml-5" })
                      : _vm._e(),
                    _vm.timeSheet === false
                      ? _c("div", { staticStyle: { "margin-left": "5px" } })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "ml-2 mr-1" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { name: "check-button", switch: "" },
                            model: {
                              value: _vm.timeSheet,
                              callback: function($$v) {
                                _vm.timeSheet = $$v
                              },
                              expression: "timeSheet"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "switch-icon-left" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { icon: "CheckIcon" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "switch-icon-right" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "rgba(8, 4, 51, 0.5)" },
                                  attrs: { icon: "XIcon" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box-item ml-2 mr-2 d-flex flex-row" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "padding-top": "4px", "padding-left": "5px" }
                  },
                  [
                    _c(
                      "b-avatar",
                      { attrs: { variant: "" } },
                      [
                        _c("feather-icon", {
                          staticStyle: { color: "#B2B2B2" },
                          attrs: { icon: "AlignJustifyIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticStyle: { "padding-top": "0px" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pl-1",
                      staticStyle: { "font-size": "20px", color: "#E5E5E5" }
                    },
                    [_vm._v("|")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: {
                      "padding-top": "4px",
                      "font-size": "14px",
                      color: "#171822",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v("Locum Requests")]
                ),
                _c(
                  "b-row",
                  {
                    staticClass: "ml-auto",
                    staticStyle: { "padding-top": "4px" }
                  },
                  [
                    _c("div", { staticClass: "ml-5" }),
                    _c("div", { staticStyle: { "margin-left": "5px" } }),
                    _c(
                      "div",
                      { staticClass: "ml-2 mr-1" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { name: "check-button", switch: "" },
                            model: {
                              value: _vm.locumRequest,
                              callback: function($$v) {
                                _vm.locumRequest = $$v
                              },
                              expression: "locumRequest"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "switch-icon-left" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { icon: "CheckIcon" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "switch-icon-right" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "rgba(8, 4, 51, 0.5)" },
                                  attrs: { icon: "XIcon" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box-item ml-2 mr-2 d-flex flex-row" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "padding-top": "4px", "padding-left": "5px" }
                  },
                  [
                    _c(
                      "b-avatar",
                      { attrs: { variant: "" } },
                      [
                        _c("feather-icon", {
                          staticStyle: { color: "#B2B2B2" },
                          attrs: { icon: "AlignJustifyIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticStyle: { "padding-top": "0px" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pl-1",
                      staticStyle: { "font-size": "20px", color: "#E5E5E5" }
                    },
                    [_vm._v("|")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: {
                      "padding-top": "4px",
                      "font-size": "14px",
                      color: "#171822",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v("Leaves")]
                ),
                _c(
                  "b-row",
                  {
                    staticClass: "ml-auto",
                    staticStyle: { "padding-top": "4px" }
                  },
                  [
                    _vm.leaves_rolling === false
                      ? _c("div", { staticClass: "ml-5" })
                      : _vm._e(),
                    _vm.leaves_rolling === false
                      ? _c("div", { staticStyle: { "margin-left": "5px" } })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "ml-2 mr-1" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { name: "check-button", switch: "" },
                            model: {
                              value: _vm.leaves_rolling,
                              callback: function($$v) {
                                _vm.leaves_rolling = $$v
                              },
                              expression: "leaves_rolling"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "switch-icon-left" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { icon: "CheckIcon" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "switch-icon-right" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "rgba(8, 4, 51, 0.5)" },
                                  attrs: { icon: "XIcon" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box-item ml-2 mr-2 d-flex flex-row" },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "padding-top": "4px", "padding-left": "5px" }
                  },
                  [
                    _c(
                      "b-avatar",
                      { attrs: { variant: "" } },
                      [
                        _c("feather-icon", {
                          staticStyle: { color: "#B2B2B2" },
                          attrs: { icon: "AlignJustifyIcon", size: "18" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticStyle: { "padding-top": "0px" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "pl-1",
                      staticStyle: { "font-size": "20px", color: "#E5E5E5" }
                    },
                    [_vm._v("|")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: {
                      "padding-top": "4px",
                      "font-size": "14px",
                      color: "#171822",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v("Shift swap Requests")]
                ),
                _c(
                  "b-row",
                  {
                    staticClass: "ml-auto",
                    staticStyle: { "padding-top": "4px" }
                  },
                  [
                    _c("div", { staticClass: "ml-5" }),
                    _c("div", { staticStyle: { "margin-left": "5px" } }),
                    _c(
                      "div",
                      { staticClass: "ml-2 mr-1" },
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: { name: "check-button", switch: "" },
                            model: {
                              value: _vm.swap_shift,
                              callback: function($$v) {
                                _vm.swap_shift = $$v
                              },
                              expression: "swap_shift"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "switch-icon-left" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "white" },
                                  attrs: { icon: "CheckIcon" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "switch-icon-right" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { color: "rgba(8, 4, 51, 0.5)" },
                                  attrs: { icon: "XIcon" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex  mt-5 ml-2" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    attrs: { type: "submit", variant: "primary" },
                    on: { click: _vm.updateDashboard }
                  },
                  [_vm._v(" Save ")]
                ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(186, 191, 199, 0.15)",
                        expression: "'rgba(186, 191, 199, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    staticClass: "text-primary ml-1 shadow",
                    attrs: { type: "button", variant: "outline-white" },
                    on: { click: hide }
                  },
                  [_vm._v(" Cancel ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }