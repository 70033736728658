var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.length > 0
        ? _c("b-card", { staticClass: "space mr-1" }, [
            _c(
              "div",
              { staticClass: "d-flex " },
              [
                _c("b-col", { attrs: { cols: "12" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b-avatar", {
                        attrs: {
                          size: "40",
                          src: _vm.getUserImage(_vm.user_id)
                        }
                      }),
                      _c("div", { staticClass: "ml-1" }, [
                        _c("h5", { staticClass: " font-weight-700" }, [
                          _c("span", [_vm._v(_vm._s(_vm.user))])
                        ]),
                        _c("span", { staticClass: "mr-1" }, [
                          _vm._v(" " + _vm._s(_vm.count) + " time sheets ")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-auto pt-1" },
                        [
                          _c("feather-icon", {
                            staticClass: "float-right",
                            staticStyle: { color: "#B2B2B2" },
                            attrs: { icon: "ChevronRightIcon", size: "18" },
                            on: {
                              click: function($event) {
                                return _vm.goToTimeSheet()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _vm.timeSheets.time_sheets_count === 0
            ? _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "empty-state py-5" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "160",
                          height: "160",
                          viewBox: "0 0 160 160",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d:
                              "M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z",
                            fill: "#F0F8FC"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M66.1895 87.4937H92.1618M66.1895 100.484H83.5058",
                            stroke: "#1B9AAA",
                            "stroke-width": "3",
                            "stroke-linecap": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M81.3234 40.7456H54.3247C50.8315 40.7456 48 43.5763 48 47.0666V116.679C48 120.169 50.8315 123 54.3247 123H102.28C105.778 123 108.609 120.169 108.609 116.679V68.0303",
                            stroke: "#171822",
                            "stroke-width": "3",
                            "stroke-linecap": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M111.522 45.8106C111.888 46.9881 112.084 48.2401 112.084 49.5382C112.084 56.4629 106.471 62.0764 99.5463 62.0764C92.622 62.0764 87.0081 56.4629 87.0081 49.5382C87.0081 42.6135 92.622 37 99.5463 37C101.268 37 102.91 37.3472 104.403 37.9755",
                            stroke: "#171822",
                            "stroke-width": "2.5",
                            "stroke-linecap": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M109.825 42.3568C109.157 41.4007 108.357 40.5423 107.453 39.8071",
                            stroke: "#171822",
                            "stroke-width": "2.5",
                            "stroke-linecap": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M98.7554 43.0996V50.1572C98.7554 50.2105 98.7983 50.2536 98.8525 50.2536H104.855",
                            stroke: "#1B9AAA",
                            "stroke-width": "2.5",
                            "stroke-linecap": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d:
                              "M137.872 55.3193C140.222 55.3193 142.127 57.2249 142.127 59.5747C142.127 61.9244 140.222 63.83 137.872 63.83C135.522 63.83 133.616 61.9244 133.616 59.5747C133.616 57.2249 135.522 55.3193 137.872 55.3193Z",
                            fill: "#DCEEF8"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d:
                              "M149.788 41.7021C153.078 41.7021 155.745 44.3694 155.745 47.6596C155.745 50.9498 153.078 53.617 149.788 53.617C146.498 53.617 143.831 50.9498 143.831 47.6596C143.831 44.3694 146.498 41.7021 149.788 41.7021Z",
                            fill: "#DCEEF8"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d:
                              "M130.212 40C131.153 40 131.914 40.7617 131.914 41.7021C131.914 42.6426 131.153 43.4043 130.212 43.4043C129.272 43.4043 128.51 42.6426 128.51 41.7021C128.51 40.7617 129.272 40 130.212 40Z",
                            fill: "#DCEEF8"
                          }
                        })
                      ]
                    ),
                    _c("p", { staticClass: "txt-grey" }, [
                      _vm._v("No Time Sheets")
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }